import {
  Component,
  effect,
  ElementRef,
  HostListener,
  ViewChild
} from '@angular/core';
import { GridRecord } from '../../data/type/grid/grid-record.type';
import { GridHeader } from '../../data/type/grid/grid-header.type';
import { DataSheetStore } from '../../service/store/data-sheet.store';
import { GridData } from '../../data/type/organization/grid-data.type';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicDataGridFilterService } from '../../service/public-data-grid-filter.service';
import { FilterRefEnum } from '../../data/enum/grid/filter-ref.enum';
import { FILTER_WIDTH } from '../../data-sheet.component';
import { AuthorizationService } from '../../../common/service/authorization.service';

@Component({
  selector: 'mul-data-grid',
  templateUrl: './data-grid.component.html'
})
export class DataGridComponent {
  public datasource: GridData[] = [];
  public gridRecord: GridRecord | null = null;
  public evSequence: number[] = [];
  public recordDate: Date = new Date();
  public headers: GridHeader[] | null = null;
  public filterWidth = 0;

  @ViewChild('toolbar', { static: true }) toolbar: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly gridFilterService: PublicDataGridFilterService,
    private readonly dataSheetStore: DataSheetStore,
    private readonly spinner: NgxSpinnerService
  ) {
    this.dataSheetStore.gridDisplayPageData$.subscribe(data => {
      this.datasource = data;
    });

    this.dataSheetStore.gridHeader$.subscribe(headers => {
      if (headers && headers.length > 0) this.spinner?.hide('global');
    });

    this.dataSheetStore.gridHeader$.subscribe(data => (this.headers = data));

    this.dataSheetStore.gridRecord$.subscribe({
      next: data => {
        this.gridRecord = data;

        if (data != null && 'recordDate' in data) {
          this.recordDate = new Date(data.recordDate);

          const year = this.recordDate.getFullYear();

          for (let i = 0; i < 3; ++i) {
            this.evSequence.push(year + i);
          }
        }
      }
    });

    effect(() => {
      this.filterWidth = FILTER_WIDTH();
      this._setToolBarWidth();
    });
  }

  @HostListener('window:resize')
  listener() {
    this._setToolBarWidth();
  }

  _setToolBarWidth() {
    this.toolbar.nativeElement.style.width =
      window.innerWidth - this.filterWidth - 5 + 'px';
  }

  isStatusOff(record: GridData) {
    const statusRef = this.gridFilterService.dataSheetStorageService.fieldMap.get(
      FilterRefEnum.STATUS
    );

    return statusRef ? !record[statusRef] : false;
  }
}
