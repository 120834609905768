import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import {
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule
} from '@angular/forms';
import { UIOptionType } from '../../../data-sheet/data/type/filter/UIOption.type';
import { UiObserverStore } from '../../service/ui-observer.store';

@Component({
  selector: 'mul-select',
  standalone: true,
  imports: [NgForOf, ReactiveFormsModule, NgIf],
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit {
  @ViewChild('uiContainer') uiContainer: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  @Input() formGroupName!: string;
  @Input() options!: UIOptionType[];
  @Output() itemSelectedEvent = new EventEmitter<void>();
  form!: FormGroup;
  selectStatus = false;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private readonly uiObserverService: UiObserverStore
  ) {
    effect(() => {
      if (this.uiObserverService.uiFocusObserver !== this.formGroupName) {
        this.selectStatus = false;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  @HostListener('document:mousedown', ['$event.target'])
  onGlobalClick(target: HTMLElement): void {
    if (!this.uiContainer?.nativeElement.contains(target)) {
      this.selectStatus = false;
    }
  }

  get selectedItem() {
    return this.options.find(
      it =>
        it.index === this.rootFormGroup.control.get(this.formGroupName)?.value
    )?.label;
  }

  toggle() {
    this.selectStatus = !this.selectStatus;

    if (this.selectStatus) {
      this.uiObserverService.uiFocusObserver = this.formGroupName;
    }
  }

  select(item: UIOptionType) {
    this.selectStatus = false;

    this.rootFormGroup.control?.get(this.formGroupName)?.patchValue(item.index);
    this.itemSelectedEvent.emit();
  }
}
