import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountRecoveryComponent } from './components/account-recovery/account-recovery.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { SubscriptionModule } from '../subscription/subscription.module';
import { DataSheetModule } from '../data-sheet/data-sheet.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MobileNoticeComponent } from './components/mobile-notice/mobile-notice.component';
import { AccessPromoComponent } from './components/access-promo/access-promo.component';
import { FooterComponent } from '../uicommon/components/footer/footer.component';

@NgModule({
  declarations: [
    LoginComponent,
    AccountRecoveryComponent,
    ResetPasswordComponent,
    SignUpComponent,
    ForbiddenComponent,
    MobileNoticeComponent,
    AccessPromoComponent
  ],
  exports: [MobileNoticeComponent, AccessPromoComponent, SignUpComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    SubscriptionModule,
    DataSheetModule,
    NgxSpinnerModule,
    NgOptimizedImage,
    FooterComponent
  ]
})
export class AuthModule {}
