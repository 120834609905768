import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  FinancialFilter,
  PublicDataFilter,
  PublicDataFilterFactory
} from '../../data/type/filter/public/public-data-filter.type';

@Injectable({
  providedIn: 'root'
})
export class PublicDataFilterStore {
  private _gridFilter: BehaviorSubject<PublicDataFilter> = new BehaviorSubject<
    PublicDataFilter
  >(PublicDataFilterFactory.generate());

  private _filterCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  readonly gridFilter$ = this._gridFilter.asObservable();

  readonly filterCount$ = this._filterCount.asObservable();

  set verticals(val: string[]) {
    this.gridFilter = { ...this.gridFilter, tags: val };
  }

  get verticals(): string[] {
    return this.gridFilter.tags;
  }

  set organizations(val: string[]) {
    this.gridFilter = { ...this.gridFilter, organizations: val };
  }

  get organizations(): string[] {
    return this.gridFilter.organizations;
  }

  set organizationRows(val: string[]) {
    this.gridFilter = { ...this.gridFilter, organizationRows: val };
  }

  get organizationRows(): string[] {
    return this.gridFilter.organizationRows;
  }

  set geographies(val: string[]) {
    this.gridFilter = { ...this.gridFilter, geographies: val };
  }

  get geographies(): string[] {
    return this.gridFilter.geographies;
  }

  set enterpriseValue(val: FinancialFilter) {
    this.gridFilter = { ...this.gridFilter, ev: val };
  }

  get enterpriseValue(): FinancialFilter {
    return this.gridFilter.ev;
  }

  set revenue(val: FinancialFilter) {
    this.gridFilter = { ...this.gridFilter, revenue: val };
  }

  get revenue(): FinancialFilter {
    return this.gridFilter.revenue;
  }

  set revenueGrowth(val: FinancialFilter) {
    this.gridFilter = { ...this.gridFilter, revenueGrowth: val };
  }

  get revenueGrowth(): FinancialFilter {
    return this.gridFilter.revenueGrowth;
  }

  set ebitda(val: FinancialFilter) {
    this.gridFilter = { ...this.gridFilter, ebitda: val };
  }

  get ebitda(): FinancialFilter {
    return this.gridFilter.ebitda;
  }

  set profitable(val: boolean) {
    this.gridFilter = { ...this.gridFilter, profitable: val };
  }

  get profitable(): boolean {
    return this.gridFilter.profitable;
  }

  set filterCount(val: number) {
    this._filterCount.next(val);
  }

  get filterCount() {
    return this._filterCount.getValue();
  }

  set gridFilter(val: PublicDataFilter) {
    this._gridFilter.next(val);
  }

  get gridFilter(): PublicDataFilter {
    return this._gridFilter.getValue();
  }
}
