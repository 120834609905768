import { Component, OnDestroy } from '@angular/core';
import { PublicDataGridFilterService } from '../../../service/public-data-grid-filter.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mul-public-filter-clear',
  templateUrl: './public-filter-clear.component.html'
})
export class PublicFilterClearComponent implements OnDestroy {
  publicGridFilterCountSubscription: Subscription = new Subscription();

  filterCount = 0;

  constructor(
    public readonly publicDataGridFilterService: PublicDataGridFilterService,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.publicGridFilterCountSubscription = this.publicDataGridFilterService.gridFilterStore.filterCount$.subscribe(
      {
        next: val => {
          this.filterCount = val;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.publicGridFilterCountSubscription.unsubscribe();
  }

  clearFilters() {
    this.publicDataGridFilterService.clearFilters();

    this.uiObserverService.uiFocusObserver = null;
  }
}
