import {
  Component,
  effect,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { StoredGridFilter } from '../../../data/interface/stored-grid/stored-grid.interface';
import { StoredGridService } from '../../../service/stored-grid.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';
import { UiComponentRegisterEnum } from '../../../../uicommon/data/enum/uiComponentRegister.enum';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { Subscription } from 'rxjs';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { NavigationItemType } from '../../../../navigation/data/type/navigation-item.type';
import { DataType } from '../../../../navigation/data/emun/data-type';
import { StoredGridEnum } from '../../../data/enum/stored-grid/stored-grid.enum';

@Component({
  selector: 'mul-stored-grid-select',
  templateUrl: './stored-grid-select.component.html'
})
export class StoredGridSelectComponent implements OnDestroy {
  savedStoredGridSubscription: Subscription = new Subscription();
  activeSavedStoredGridSubscription: Subscription = new Subscription();
  navigationStateSubscription: Subscription = new Subscription();

  @ViewChild('uiContainer') uiContainer: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  displaySavedSearchesPanel = false;
  datasource: StoredGridFilter[] = [];
  storedGridFilters: StoredGridFilter[] = [];
  selectedItem: StoredGridFilter | undefined = undefined;
  savedSearchesActionState = false;
  navigationState: NavigationItemType = {} as NavigationItemType;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly storedGridFilterService: StoredGridService,
    private readonly navigationStore: NavigationStore,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;

        this.datasource = this._setStoredGridDataSource();
      }
    );

    this.savedStoredGridSubscription = this.storedGridFilterService.storedGridStore.savedStoredGrid$.subscribe(
      val => {
        this.storedGridFilters = val;

        this.datasource = this._setStoredGridDataSource();

        if (this.selectedItem) {
          this.selectedItem = this.datasource.find(
            it => it.id === this.selectedItem!.id
          );
        }

        this.savedSearchesActionState = false;
      }
    );

    this.activeSavedStoredGridSubscription = this.storedGridFilterService.storedGridStore.activeSavedStoredGrid$.subscribe(
      val => {
        this.selectedItem = val;

        this.savedSearchesActionState = false;
      }
    );

    effect(() => {
      if (
        this.uiObserverService.uiFocusObserver !==
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH
      ) {
        this.displaySavedSearchesPanel = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.savedStoredGridSubscription.unsubscribe();
    this.activeSavedStoredGridSubscription.unsubscribe();
    this.navigationStateSubscription.unsubscribe();
  }

  @HostListener('document:mousedown', ['$event.target'])
  onGlobalClick(target: HTMLElement): void {
    if (!this.uiContainer?.nativeElement.contains(target)) {
      this.close();
    }
  }

  toggle() {
    this.displaySavedSearchesPanel = !this.displaySavedSearchesPanel;

    if (this.displaySavedSearchesPanel) {
      this.uiObserverService.uiFocusObserver =
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH;
    }
  }

  load(storedGridFilter: StoredGridFilter) {
    this.displaySavedSearchesPanel = false;
    this.savedSearchesActionState = true;
    this.storedGridFilterService.storedGridStore.activeSavedStoredGrid = storedGridFilter;
  }

  close() {
    this.displaySavedSearchesPanel = false;
  }

  private _setStoredGridDataSource() {
    if (this.navigationState?.dataType === DataType.DEALS) {
      return this.storedGridFilters.filter(
        it => it.type === StoredGridEnum.DEAL
      );
    }

    return this.storedGridFilters.filter(
      it => !it.type || it.type === StoredGridEnum.PUBLIC
    );
  }
}
