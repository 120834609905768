import { Sort, SortFactory } from '../../type/grid/sort.type';
import {
  PublicDataFilter,
  PublicDataFilterFactory
} from '../../type/filter/public/public-data-filter.type';
import {
  Mutation,
  MutationFactory
} from '../../type/mutator/data-mutator.type';
import {
  DealDataFilter,
  DealDataFilterFactory
} from '../../type/filter/deal/deal-data-filter.type';
import { StoredGridEnum } from '../../enum/stored-grid/stored-grid.enum';

export interface StoredGridFilter {
  id?: string | null;
  saved?: boolean;
  name?: string;
  description?: string;
  view: string;
  sorting: Sort;
  page: number;
  mutation: Mutation;
  filter: PublicDataFilter | DealDataFilter;
  type?: StoredGridEnum;
}

export class StoredPublicGridFilterFactory {
  static generate() {
    return <StoredGridFilter>{
      id: null,
      view: '',
      sorting: SortFactory.generate(),
      page: 1,
      filter: PublicDataFilterFactory.generate(),
      mutation: MutationFactory.generate(),
      type: StoredGridEnum.PUBLIC
    };
  }
}

export class StoredDealGridFilterFactory {
  static generate() {
    return <StoredGridFilter>{
      id: null,
      view: '',
      sorting: SortFactory.generate(),
      page: 1,
      filter: DealDataFilterFactory.generate(),
      mutation: MutationFactory.generate(),
      type: StoredGridEnum.DEAL
    };
  }
}
