import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { Observable, tap, throwError } from 'rxjs';
import { StoredPublicGridSavedResponse } from '../data/type/filter/stored-grid/stored-grid-saved-response.type';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { StoredGridFilter } from '../data/interface/stored-grid/stored-grid.interface';
import { FilterStorage } from './store/filter.storage';
import { DataSortingService } from './data-sorting.service';
import { PublicDataGridFilterService } from './public-data-grid-filter.service';
import { DataPaginationService } from './data-pagination.service';
import { GridMutatorService } from './mutator/grid-mutator.service';
import { StoredGridStore } from './store/stored-grid.store';
import { catchError } from 'rxjs/operators';
import { StoredGridEnum } from '../data/enum/stored-grid/stored-grid.enum';
import { PublicDataFilter } from '../data/type/filter/public/public-data-filter.type';
import { DealDataGridFilterService } from './deal-data-grid-filter.service';
import { DealDataFilter } from '../data/type/filter/deal/deal-data-filter.type';

@Injectable({
  providedIn: 'root'
})
export class StoredGridService {
  constructor(
    public storedGridStore: StoredGridStore,
    private readonly publicDataGridFilterService: PublicDataGridFilterService,
    private readonly dealDataGridFilterService: DealDataGridFilterService,
    private readonly dataSortingService: DataSortingService,
    private readonly dataPaginationService: DataPaginationService,
    private readonly gridMutatorService: GridMutatorService,
    private readonly filterStorage: FilterStorage,
    private readonly api: ApiService
  ) {
    this.storedGridStore.activeSavedStoredGrid$.subscribe(val => {
      if (val) this.applyStoredFilter(val);
    });
  }

  findAllBySaved$(): Observable<StoredPublicGridSavedResponse[]> {
    return this.api
      .httpGet$<StoredPublicGridSavedResponse[]>(ApiRoutes.STORED_GRID)
      .pipe(
        tap(val => {
          console.debug('LOAD USER SAVED PUBLIC STORED FILTERS');

          this.storedGridStore.savedStoredGrids = val;
        })
      );
  }

  findById$(id: string): Observable<StoredGridFilter> {
    return this.api
      .httpGet$<StoredGridFilter>(ApiRoutes.STORED_GRID + `/${id}`)
      .pipe(
        tap(() => {
          console.debug('STORED-GRID');
        })
      );
  }

  delete$(id: string) {
    return this.api
      .httpDelete$(ApiRoutes.STORED_GRID + `/${id}`)
      .pipe(catchError(err => throwError(() => err)));
  }

  save$(
    type?: StoredGridEnum,
    name?: string,
    saved?: boolean
  ): Observable<StoredPublicGridSavedResponse> {
    return this.api.httpPost$<StoredPublicGridSavedResponse>(
      ApiRoutes.STORED_GRID,
      {
        view: this.filterStorage.activeGridView,
        sorting: this.dataSortingService.gridSortStore.sort,
        page: this.dataPaginationService.dataPaginationStore.page,
        filter:
          type === StoredGridEnum.PUBLIC
            ? this.publicDataGridFilterService.gridFilterStore.gridFilter
            : this.dealDataGridFilterService.dealDataFilterStore.gridFilter,
        mutation: this.gridMutatorService.gridMutatorStorage.gridMutation,
        type: type,
        name,
        saved
      }
    );
  }

  update$(
    id: string,
    type?: StoredGridEnum
  ): Observable<StoredPublicGridSavedResponse> {
    return this.api.httpPut$<StoredPublicGridSavedResponse>(
      ApiRoutes.STORED_GRID + `/${id}`,
      {
        view: this.filterStorage.activeGridView,
        sorting: this.dataSortingService.gridSortStore.sort,
        page: this.dataPaginationService.dataPaginationStore.page,
        filter:
          type === StoredGridEnum.PUBLIC
            ? this.publicDataGridFilterService.gridFilterStore.gridFilter
            : this.dealDataGridFilterService.dealDataFilterStore.gridFilter,
        mutation: this.gridMutatorService.gridMutatorStorage.gridMutation,
        saved: true
      }
    );
  }

  updateDefinition$(
    id: string,
    name: string
  ): Observable<StoredPublicGridSavedResponse> {
    return this.api.httpPut$<StoredPublicGridSavedResponse>(
      ApiRoutes.STORED_GRID_DEFINITION + `/${id}`,
      {
        name: name,
        saved: true
      }
    );
  }

  applyStoredFilter(val: StoredGridFilter) {
    if (val.type === StoredGridEnum.DEAL) {
      this.dealDataGridFilterService.dealDataFilterStore.gridFilter = <
        DealDataFilter
      >val.filter;
    } else {
      this.publicDataGridFilterService.gridFilterStore.gridFilter = <
        PublicDataFilter
      >val.filter;
    }

    this.gridMutatorService.gridMutatorStorage.gridMutation = val.mutation;
    this.dataSortingService.gridSortStore.sort = val.sorting;
    this.dataPaginationService.dataPaginationStore.page = val.page;
    this.filterStorage.activeGridView = val.view;
  }
}
