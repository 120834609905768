import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterTriggerEvent {
  triggerPublicFilter: Subject<boolean> = new Subject<boolean>();
  triggerDealFilter: Subject<boolean> = new Subject<boolean>();

  triggerPublicFilter$ = this.triggerPublicFilter.asObservable();
  triggerDealFilter$ = this.triggerDealFilter.asObservable();
}
