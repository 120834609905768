<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div class="auth mb-5" [style]="{ width: '22rem' }">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>

    <form
      class="flex flex-col items-center justify-center flex-1"
      (ngSubmit)="onSubmitForm()"
      [formGroup]="formGroup"
    >
      <p>
        Please provide a new password for your account.
      </p>

      <div class="flex flex-col w-full my-5 group">
        <label for="password">Password</label>
        <div
          class="flex flex-row align-middle password group-focus-within:ring-1 group-focus-within:ring-primary-300 group-focus-within:border-primary-300"
        >
          <input
            class="group-focus-within:focus-0 group-focus-within:outline-none"
            id="password"
            formControlName="password"
            [type]="passwordFieldVisibility ? 'text' : 'password'"
          />
          <span
            class="fa-regular fa-eye {{
              passwordFieldVisibility ? 'fa-eye-slash' : 'fa-eye'
            }}"
            (click)="passwordFieldVisibility = !passwordFieldVisibility"
          >
          </span>
        </div>
        <small
          class="ml-1 text-red-400"
          *ngIf="
            formGroup.controls['password'].touched &&
            formGroup.controls['password']?.hasError('required')
          "
        >
          Please provide a user account password
        </small>
      </div>

      <div class="flex flex-row justify-around links">
        <a routerLink="/authorization/login" class="underline">Back to login</a>
      </div>

      <button type="submit">
        Restore account password
      </button>
    </form>
  </div>
  <mul-footer></mul-footer>
</main>
