import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { Router } from '@angular/router';
import { AuthModeEnum } from '../../data/enum/auth-mode.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AuthDomainInitializerService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  embeddedDomainAuth() {
    const host = window.location.origin;
    const parentHost = document.referrer.slice(0, -1);

    if (this.authService.isDomainLogin()) {
      this.spinner?.show('global');

      this.authService.embeddedDomainLogin(host, parentHost).subscribe({
        next: res => {
          if (res) {
            this.router.navigate(['/data-sheet']).finally();
          } else {
            this.spinner?.hide('global');
          }
        },
        error: () =>
          this.router.navigate(['/authorization/unauthorized-access'])
      });
    } else {
      this.spinner?.hide('global');
    }

    if (
      !parentHost &&
      this.authService.userData().authMode === AuthModeEnum.embedded
    ) {
      this.authService.logOut();
    }
  }
}
