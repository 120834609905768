import { Component, Input } from '@angular/core';
import {
  ColumnField,
  GridHeader
} from '../../../data/type/grid/grid-header.type';
import { ColumnFieldTypeEnum } from '../../../data/enum/grid/column-field-type.enum';
import { DataSortingService } from '../../../service/data-sorting.service';

@Component({
  selector: 'mul-header-cell',
  templateUrl: './header-cell.component.html'
})
export class HeaderCellComponent {
  @Input() header!: GridHeader;

  sortDirection = 1;

  constructor(private readonly dataSortingService: DataSortingService) {}

  sortColumn(fields: ColumnField[]) {
    this.sortDirection *= -1;

    this.dataSortingService.gridSortStore.sort = {
      direction: this.sortDirection,
      column: this.getFieldSetReference(fields)
    };
  }

  generateClassNameFromLabel(label: string): string {
    return label
      .toLowerCase()
      .replace('|', '')
      .replace(' ', '-')
      .replace('/', '-');
  }

  private getFieldSetReference(fields: ColumnField[]): string {
    let refField: string = fields.find(it => it.sortable)?.ref || fields[0].ref;

    if (fields.length > 1) {
      fields.forEach(fld => {
        if (
          fld.type == ColumnFieldTypeEnum.STRING ||
          fld.type == ColumnFieldTypeEnum.NUMBER
        ) {
          refField = fld.ref;
        }
      });
    }

    return refField;
  }
}
