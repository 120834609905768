import { BuyerTypeEnum } from '../../../enum/grid/buyer-type.enum';

export type BuyerFilterType = {
  keywords: string[];
  organizationSelection: string[];
  geographySelection: string[];
  sponsorSelection: string[];
  buyerTypes: BuyerTypeEnum[];
};

export class BuyerFilterFactory {
  static generate() {
    return <BuyerFilterType>{
      keywords: [],
      organizationSelection: [],
      geographySelection: [],
      sponsorSelection: [],
      buyerTypes: [
        BuyerTypeEnum.PUBLIC_CO,
        BuyerTypeEnum.PRIVATE_CO,
        BuyerTypeEnum.PRIVATE_EQ,
        BuyerTypeEnum.BACKED_CO
      ]
    };
  }
}
