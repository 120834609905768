import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { BuyerFilterType } from '../../../data/type/filter/deal/buyer-filter-type';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuyerTypeEnum } from '../../../data/enum/grid/buyer-type.enum';

@Component({
  selector: 'mul-buyer-filter',
  templateUrl: './buyer-filter.component.html'
})
export class BuyerFilterComponent implements OnChanges {
  @Output() dataMutationEventEmitter: EventEmitter<
    BuyerFilterType
  > = new EventEmitter();

  @Input() filterSelection: BuyerFilterType = {} as BuyerFilterType;

  @Input() geographyDataSet: UIOptionType[] = [];
  @Input() verticalDataSet: UIOptionType[] = [];
  @Input() buyerOrganizationsDataSet: UIOptionType[] = [];
  @Input() sponsorDataSet: UIOptionType[] = [];

  geographySelection: UIOptionType[] = [];
  buyerOrganizationSelection: UIOptionType[] = [];
  sponsorSelection: UIOptionType[] = [];

  filterForm: FormGroup = this.formBuilder.group({
    keywords: '',
    strategicAcquireGroup: true,
    publicCompanyType: true,
    privateCompanyType: true,
    financialBuyerGroup: true,
    privateEquityType: true,
    peBackedPortCo: true
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnChanges(): void {
    this.geographySelection = this.geographyDataSet.filter(it =>
      this.filterSelection.geographySelection?.includes(it.ref)
    );

    this.buyerOrganizationSelection = this.buyerOrganizationsDataSet.filter(
      it => this.filterSelection.organizationSelection?.includes(it.ref)
    );

    this.sponsorSelection = this.buyerOrganizationsDataSet.filter(it =>
      this.filterSelection.sponsorSelection?.includes(it.ref)
    );

    this.filterForm
      .get('keywords')
      ?.patchValue(this.filterSelection.keywords?.join(', '));
  }

  setOrganizations($event: string[]) {
    this.filterSelection.organizationSelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setGeography($event: string[]) {
    this.filterSelection.geographySelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setSponsor($event: string[]) {
    this.filterSelection.sponsorSelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onFormKeywordsItemChange() {
    const keywords: string = this.filterForm.get('keywords')?.value;

    this.filterSelection.keywords = keywords.length
      ? keywords.split(',').map(it => it.trim())
      : [];

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setStrategicAcquireGroup() {
    this.filterForm
      .get('publicCompanyType')
      ?.patchValue(this.filterForm.get('strategicAcquireGroup')?.value);
    this.filterForm
      .get('privateCompanyType')
      ?.patchValue(this.filterForm.get('strategicAcquireGroup')?.value);

    this.setPublicCorporation();
    this.setPrivateCorporation();
  }

  setPublicCorporation() {
    if (this.filterForm.get('publicCompanyType')?.value) {
      this.filterForm.get('strategicAcquireGroup')?.patchValue(true);
      this.setBuyerType(BuyerTypeEnum.PUBLIC_CO);
    } else {
      this.unsetBuyerType(BuyerTypeEnum.PUBLIC_CO);
    }

    if (
      !this.filterForm.get('privateCompanyType')?.value &&
      !this.filterForm.get('publicCompanyType')?.value
    ) {
      this.filterForm.get('strategicAcquireGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setPrivateCorporation() {
    if (this.filterForm.get('privateCompanyType')?.value) {
      this.filterForm.get('strategicAcquireGroup')?.patchValue(true);
      this.setBuyerType(BuyerTypeEnum.PRIVATE_CO);
    } else {
      this.unsetBuyerType(BuyerTypeEnum.PRIVATE_CO);
    }

    if (
      !this.filterForm.get('privateCompanyType')?.value &&
      !this.filterForm.get('publicCompanyType')?.value
    ) {
      this.filterForm.get('strategicAcquireGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setFinancialBuyerGroup() {
    this.filterForm
      .get('privateEquityType')
      ?.patchValue(this.filterForm.get('financialBuyerGroup')?.value);
    this.filterForm
      .get('peBackedPortCo')
      ?.patchValue(this.filterForm.get('financialBuyerGroup')?.value);

    this.setPrivateEquity();
    this.setBackedCorporation();
  }

  setPrivateEquity() {
    if (this.filterForm.get('privateEquityType')?.value) {
      this.filterForm.get('financialBuyerGroup')?.patchValue(true);
      this.setBuyerType(BuyerTypeEnum.PRIVATE_EQ);
    } else {
      this.unsetBuyerType(BuyerTypeEnum.PRIVATE_EQ);
    }

    if (
      !this.filterForm.get('privateEquityType')?.value &&
      !this.filterForm.get('peBackedPortCo')?.value
    ) {
      this.filterForm.get('financialBuyerGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setBackedCorporation() {
    if (this.filterForm.get('peBackedPortCo')?.value) {
      this.filterForm.get('financialBuyerGroup')?.patchValue(true);
      this.setBuyerType(BuyerTypeEnum.BACKED_CO);
    } else {
      this.unsetBuyerType(BuyerTypeEnum.BACKED_CO);
    }

    if (
      !this.filterForm.get('privateEquityType')?.value &&
      !this.filterForm.get('peBackedPortCo')?.value
    ) {
      this.filterForm.get('financialBuyerGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  private setBuyerType(type: BuyerTypeEnum) {
    const index = this.filterSelection.buyerTypes.indexOf(type);

    if (index < 0) this.filterSelection.buyerTypes.push(type);
  }

  private unsetBuyerType(type: BuyerTypeEnum) {
    const index = this.filterSelection.buyerTypes.indexOf(type);

    if (index >= 0) this.filterSelection.buyerTypes.splice(index, 1);
  }
}
