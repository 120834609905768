import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

const baseUrl = '/api';

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  protected constructor(private http: HttpClient) {}

  public httpGet$<R>(url: string, opts = {}): Observable<R> {
    return this.http.get<R>(baseUrl + url, opts);
  }

  public httpPost$<R>(url: string, data = {}, opts = {}): Observable<R> {
    return this.http.post<R>(baseUrl + url, data, opts);
  }

  public httpPut$<R>(url: string, data = {}, opts = {}): Observable<R> {
    return this.http.put<R>(baseUrl + url, data, opts);
  }

  public httpDelete$<R>(url: string, opts = {}): Observable<R> {
    return this.http.delete<R>(baseUrl + url, opts);
  }

  private handleError(errRes: HttpErrorResponse): Observable<never> {
    const errorMessage = 'An unknown error occurred';

    if (!errRes.error && !errRes.error?.data) {
      return throwError(() => errorMessage);
    }

    if (errRes.error.data) {
      return throwError(errRes.error.data.error.message);
    }

    return throwError(errRes.error.message);
  }
}
