<router-outlet *ngIf="!mobile"></router-outlet>
<mul-mobile-notice *ngIf="mobile"></mul-mobile-notice>
<ngx-spinner
  *ngIf="!mobile"
  bdColor="{{ (darkMode$ | async) ? '#1f2937' : '#f9fafb' }}"
  color="{{ (darkMode$ | async) ? '#f9fafb' : '#1f2937' }}"
  name="global"
  size="medium"
  ><p>Loading data, just a few seconds...</p></ngx-spinner
>
<mul-access-promo></mul-access-promo>
<mul-snackbar></mul-snackbar>
<mul-dialog></mul-dialog>
