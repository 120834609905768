import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'mul-account-recovery',
  templateUrl: './account-recovery.component.html'
})
export class AccountRecoveryComponent {
  public env = environment;

  formGroup: FormGroup = this._formBuilder.group({
    username: [null, [Validators.required, Validators.email]]
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService
  ) {
    this.spinner?.hide('global');
  }

  onSubmitForm() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.spinner?.show('global');

    this.authService
      .recover(this.formGroup.controls['username'].value)
      .subscribe({
        complete: () => {
          this.router.navigate(['/authorization/login']);
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.INFO,
            message:
              "Check your email to recover your account. If you didn't receive your verification email, check your spam folder."
          });
        },
        error: () => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.ERROR,
            message:
              'An error has occur while recovering your account. Please contact customer service for further information.'
          });
        }
      });
  }
}
