import { Component, Input } from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';

@Component({
  selector: 'mul-cell-long-string',
  templateUrl: './cell-long-string.component.html'
})
export class CellLongStringComponent {
  @Input() record!: GridData;
  @Input() field!: ColumnField;
}
