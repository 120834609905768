import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { User } from '../../../common/data/entity/user.entity';
import { SubscriptionService } from '../../../subscription/services/subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { NavigationStore } from '../../service/store/navigation.store';
import { NavigationItemType } from '../../data/type/navigation-item.type';
import { RoleEnum } from '../../../common/data/enum/role.enum';
import { Subscription } from 'rxjs';
import { FilterStorage } from '../../../data-sheet/service/store/filter.storage';
import { StoredGridStore } from '../../../data-sheet/service/store/stored-grid.store';
import { PublicDataFilterStore } from '../../../data-sheet/service/store/public-data-filter.store';
import { PublicDataFilterFactory } from '../../../data-sheet/data/type/filter/public/public-data-filter.type';
import { DealDataFilterStore } from '../../../data-sheet/service/store/deal-data-filter.store';
import { DealDataFilterFactory } from '../../../data-sheet/data/type/filter/deal/deal-data-filter.type';
import { PromoEnum } from '../../../auth/data/enum/promo.enum';
import { AccessPromoService } from '../../../auth/services/access-promo.service';

@Component({
  selector: 'mul-navigation-user',
  templateUrl: './navigation-user.component.html'
})
export class NavigationUserComponent implements OnInit, OnDestroy {
  protected userData!: User;
  protected isUserMenuCollapsed = true;
  protected navigation: NavigationItemType[] = [];
  protected navigationState: NavigationItemType = {} as NavigationItemType;
  protected readonly env = environment;
  protected readonly RoleEnum = RoleEnum;

  private navigationSubscription: Subscription;
  private navigationStateSubscription: Subscription;

  constructor(
    protected readonly authorizationService: AuthorizationService,
    protected readonly authService: AuthService,
    private readonly subscriptionService: SubscriptionService,
    private readonly navigationStore: NavigationStore,
    private readonly filterStorage: FilterStorage,
    private readonly storedGridStore: StoredGridStore,
    private readonly publicDataFilterStore: PublicDataFilterStore,
    private readonly dealDataFilterStore: DealDataFilterStore,
    private readonly spinner: NgxSpinnerService,
    private readonly accessPromoService: AccessPromoService
  ) {
    this.navigationSubscription = this.navigationStore.navigation$.subscribe(
      val => {
        this.navigation = val;
      }
    );

    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.navigationStateSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userData = this.authService.userData();
  }

  logout() {
    this.spinner?.show('global');
    this.authService.logOut();
  }

  goToCustomerPortal() {
    this.spinner?.show('global');
    this.subscriptionService.createCustomerSession().subscribe(res => {
      window.location.href = res.url;
    });
  }

  toggleUserMenu() {
    this.isUserMenuCollapsed = !this.isUserMenuCollapsed;
  }

  closeUserMenu() {
    this.isUserMenuCollapsed = true;
  }

  get nav() {
    return this.navigation.filter(it => it.status);
  }

  setNavItem(item: NavigationItemType) {
    if (item.proFeature && !this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.VIEWS);
      return;
    }

    this.spinner?.show('global');

    this.navigationStore.navigationState = item;
    this.filterStorage.activeGridView = '';
    this.storedGridStore.activeSavedStoredGrid = undefined;
    this.publicDataFilterStore.gridFilter = PublicDataFilterFactory.generate();
    this.dealDataFilterStore.gridFilter = DealDataFilterFactory.generate();
  }
}
