import { Injectable } from '@angular/core';
import { forkJoin, Observable, tap, throwError } from 'rxjs';
import { GridDataResponse } from 'src/app/data-sheet/data/type/grid/grid-data-response.type';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { ApiService } from '../../../../common/service/api.service';
import { StorageService } from '../../../../common/service/storage.service';
import { Router } from '@angular/router';
import { GridDataIndexResponseType } from '../../../data/type/grid/grid-data-index-response.type';
import { catchError } from 'rxjs/operators';
import { AuthKeysEnum } from '../../../../auth/data/enum/auth-keys.enum';
import { DealDataGridFilterService } from '../../deal-data-grid-filter.service';
import { DataCellService } from '../../data-cell.service';
import { FilterRefEnum } from '../../../data/enum/grid/filter-ref.enum';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { FilterTriggerEvent } from '../../FilterTriggerEvent';

@Injectable({
  providedIn: 'root'
})
export class DealDataService {
  constructor(
    private dealDataGridFilterService: DealDataGridFilterService,
    private dataCellService: DataCellService,
    private authorizationService: AuthorizationService,
    private filterTriggerEvent: FilterTriggerEvent,
    private api: ApiService,
    private storageService: StorageService,
    private router: Router
  ) {}

  getAll$(
    viewId?: string | undefined
  ): Observable<GridDataIndexResponseType[]> {
    const route = this.authorizationService.isSubscribed()
      ? ApiRoutes.DEAL_DATA_GRID
      : ApiRoutes.DEAL_GRID_PUBLIC;

    const url = route + (viewId ? `/${viewId}` : '');

    let totalRecords = 0;
    let pages = 0;
    const limit = 2000;

    console.debug('FETCHING DEAL DATA');

    return this.api
      .httpGet$<GridDataIndexResponseType[]>(ApiRoutes.DEAL_DATA_GRID_INDEX)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.storageService.delete(AuthKeysEnum.USER_DATA_KEY);
            this.router.navigate([ApiRoutes.DATA_GRID_PUBLIC]).then(() => {});
          }

          return throwError(() => err);
        }),

        tap(res => {
          totalRecords = res[0].recordCount;
          pages = Math.ceil(totalRecords / limit);

          this._buildAsyncCallsArray(url, pages, limit).subscribe(val => {
            this._processGrid(val);
          });
        }),
        tap(() => {
          this.filterTriggerEvent.triggerDealFilter.next(true);
        })
      );
  }

  private _buildAsyncCallsArray(
    url: string,
    pages: number,
    limit: number
  ): Observable<readonly GridDataResponse[]> {
    const asyncMethods = [];

    for (let i = 1; i <= pages; i++) {
      asyncMethods.push(
        this.api.httpGet$<GridDataResponse>(`${url}?page=${i}&limit=${limit}`)
      );
    }

    return forkJoin(asyncMethods);
  }

  private _processGrid(val: readonly GridDataResponse[]) {
    const record = val[0].record;
    const headers = val[0].headers;
    const view = val[0].view;
    const data = val.flatMap(it => it.data);

    this.dealDataGridFilterService.dataSheetStorageService.gridRecord = record;

    this.dealDataGridFilterService.dataSheetStorageService.gridHeader = headers;
    this.dataCellService.setCellWidth(headers);
    this.dealDataGridFilterService.dataSheetStorageService.fieldMap = this.dataCellService.setFilterRefs(
      headers
    );

    this.dealDataGridFilterService.filterStorageService.activeGridView = view;

    this.dealDataGridFilterService.dataSheetStorageService.gridData = data;

    const targetRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.TARGET_ID
      )
    );

    const targetNameRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.TARGET_NAME
      )
    );

    const targetTagsRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.TARGET_TAGS
      )
    );

    const buyerRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.BUYER_ID
      )
    );

    const buyerNameRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.BUYER_NAME
      )
    );

    const sponsorRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.BUYER_SPONSOR_ID
      )
    );

    const sponsorNameRef = <string>(
      this.dealDataGridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.BUYER_SPONSOR_NAME
      )
    );

    const targets: UIOptionType[] = [];
    const buyers: UIOptionType[] = [];
    const sponsors: UIOptionType[] = [];

    for (let i = 0; i < data.length; i++) {
      if (!targets?.find(it => it.ref === data[i][targetRef])) {
        targets.push(<UIOptionType>{
          index:
            targetRef != undefined ? data[i][targetRef]?.toString().trim() : '',
          label:
            targetNameRef != undefined
              ? data[i][targetNameRef]?.toString().trim()
              : '',
          ref:
            targetRef != undefined ? data[i][targetRef]?.toString().trim() : '',
          meta:
            targetTagsRef != undefined
              ? data[i][targetTagsRef]
                  ?.toString()
                  .split(';')
                  .map(t => t.trim())
              : []
        });
      }

      const buyersRefs = data[i][buyerRef]?.toString().split(';') || [];
      const buyersNames = data[i][buyerNameRef]?.toString().split(';') || [];

      for (let s = 0; s < buyersRefs.length; s++) {
        if (!buyers?.find(it => it.ref === buyersRefs[s])) {
          buyers.push(<UIOptionType>{
            index: buyersRefs[s] != undefined ? buyersRefs[s].trim() : '',
            label: buyersRefs[s] != undefined ? buyersNames[s].trim() : '',
            ref: buyersRefs[s] != undefined ? buyersRefs[s].trim() : ''
          });
        }
      }

      const sponsorRefs = data[i][sponsorRef]?.toString().split(';') || [];
      const sponsorNames = data[i][sponsorNameRef]?.toString().split(';') || [];

      for (let s = 0; s < sponsorRefs.length; s++) {
        if (!sponsors?.find(it => it.ref === sponsorRefs[s])) {
          sponsors.push(<UIOptionType>{
            index: sponsorRefs[s] != undefined ? sponsorRefs[s].trim() : '',
            label: sponsorNames[s] != undefined ? sponsorNames[s].trim() : '',
            ref: sponsorRefs[s] != undefined ? sponsorRefs[s].trim() : ''
          });
        }
      }
    }

    this.dealDataGridFilterService.filterStorageService.targetOrganizations = targets.sort(
      (a, b) => (a.ref > b.ref ? 1 : -1)
    );

    this.dealDataGridFilterService.filterStorageService.buyerOrganizations = buyers.sort(
      (a, b) => (a.ref > b.ref ? 1 : -1)
    );

    this.dealDataGridFilterService.filterStorageService.sponsorOrganization = sponsors.sort(
      (a, b) => (a.ref > b.ref ? 1 : -1)
    );
  }
}
