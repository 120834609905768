import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UIOptionType } from '../../../data-sheet/data/type/filter/UIOption.type';
import { cloneDeep } from 'lodash';
import { UiObserverStore } from '../../service/ui-observer.store';
import { Subscription } from 'rxjs';
import { PromoEnum } from '../../../auth/data/enum/promo.enum';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { AccessPromoService } from '../../../auth/services/access-promo.service';

@Component({
  selector: 'mul-multi-select',
  templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent implements OnInit, OnDestroy {
  @ViewChild('uiContainer') uiContainer: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  @Output() dataMutationEventEmitter: EventEmitter<
    string[]
  > = new EventEmitter();

  @Input() dataSet!: UIOptionType[];
  @Input() label: string | undefined = undefined;
  @Input() tooltip: string | undefined = undefined;
  @Input() componentId: string = '';
  @Input() placeholder: string = '';
  @Input() displayCount: number = 5;
  @Input() selection: UIOptionType[] = [];

  componentForm: FormGroup = this.formBuilder.group({
    formField: ''
  });
  data: UIOptionType[] = [];
  componentState = false;
  displayAllSelected = false;
  multiLevel = false;
  filterFirstPolicy = false;
  filterFirstPolicyThreshold = 2500;

  private inputFieldSubscription: Subscription | undefined = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly uiObserverService: UiObserverStore,
    protected readonly authorizationService: AuthorizationService,
    private readonly accessPromoService: AccessPromoService
  ) {
    effect(() => {
      if (this.uiObserverService.uiFocusObserver !== this.componentId) {
        this.componentState = false;
      }
    });
  }

  ngOnInit(): void {
    this.multiLevel = this.dataSet.some(it => it.parent);

    this.inputFieldSubscription = this.componentForm
      .get('formField')
      ?.valueChanges.subscribe({
        next: v => {
          this.filterFirstPolicy =
            this.dataSet.length > this.filterFirstPolicyThreshold;

          if (this.componentForm.get('formField')?.dirty) {
            const filteredData = v
              ? this._filterField(this.dataSet, v || '')
              : this.dataSet;

            if (
              (this.filterFirstPolicy &&
                filteredData.length <= this.filterFirstPolicyThreshold) ||
              !this.filterFirstPolicy
            ) {
              this.data = this.multiLevel
                ? this._buildStructuredData(filteredData)
                : filteredData;

              this.componentState =
                this.data.length <= this.filterFirstPolicyThreshold;
            }
          }
        }
      });

    this._resetComponent();
  }

  ngOnDestroy(): void {
    this.inputFieldSubscription?.unsubscribe();
  }

  @HostListener('document:mousedown', ['$event.target'])
  onGlobalClick(target: HTMLElement): void {
    if (!this.uiContainer?.nativeElement.contains(target)) {
      this.exit();
    }
  }

  addItem(index: string | number) {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.FILTER);
      return;
    }

    const selectedItem = this.dataSet?.find(it => it.index == index);

    const candidates: UIOptionType[] = [];

    if (selectedItem?.parent === null) {
      candidates.push(
        ...this.dataSet.filter(it => it.parent === selectedItem.index)
      );
    } else if (selectedItem !== undefined) {
      candidates.push(selectedItem);
    }

    candidates.forEach(it => {
      if (!this.selection?.find(selection => selection.index == it.index)) {
        this.selection?.push(it);
      } else {
        if (!selectedItem?.children) {
          this.removeItem(it.index);
        }
      }
    });

    this.selection = this.selection === undefined ? [] : this.selection;

    this.dataMutationEventEmitter.emit(this.selection.map(it => it.ref));
  }

  removeItem(id: string | number) {
    const index: number | undefined = this.selection.findIndex(
      it => it.index == id
    );

    if (index != undefined) {
      this.selection.splice(index, 1);
    }

    this.dataMutationEventEmitter.emit(this.selection.map(it => it.ref));
  }

  isSelected(id: string | number): boolean {
    return this.selection.filter(it => it.index == id).length > 0;
  }

  open() {
    this.componentState = !this.filterFirstPolicy;
  }

  exit() {
    this.componentState = false;
    this._resetComponent();
    this.componentForm.get('formField')?.reset();
  }

  private _filterField(data: UIOptionType[], value: string): UIOptionType[] {
    let _data = cloneDeep(data);

    if (value) {
      if (this.multiLevel) _data = _data.filter(it => it.parent);

      _data = _data.filter(
        item =>
          item.label?.toLowerCase()?.includes(value.toLowerCase()) ||
          item.ref?.toLowerCase()?.includes(value.toLowerCase())
      );
    }

    return _data;
  }

  private _buildStructuredData(data: UIOptionType[]) {
    const _data = cloneDeep(data);
    const res: UIOptionType[] = [];

    for (let i = 0; i < this.dataSet.length; i++) {
      if (this.dataSet[i]?.parent === null) {
        res.push({ ...this.dataSet[i], children: [] });
      }
    }

    for (let i = 0; i < _data.length; i++) {
      const parent = res.find(c => c.index === _data[i].parent);

      if (parent) {
        parent.children?.push(_data[i]);
      } else {
        res.push(_data[i]);
      }
    }

    return res.filter(it => it.children?.length);
  }

  private _resetComponent() {
    if (this.filterFirstPolicy) {
      this.data = [];
    } else {
      this.data = this.multiLevel
        ? this._buildStructuredData(this.dataSet)
        : this.dataSet;
    }
  }

  isSearchParamRequired() {
    return (
      this.filterFirstPolicy &&
      this.componentForm.get('formField')?.value === ''
    );
  }
}
