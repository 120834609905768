import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent {
  public env = environment;

  constructor(private spinner: NgxSpinnerService) {
    this.spinner?.hide('global');
  }
}
