import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignUpService } from '../../services/sign-up.service';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../../subscription/services/subscription.service';
import { switchMap } from 'rxjs';
import { StripeService } from 'ngx-stripe';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { environment } from '../../../../environments/environment';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'mul-sign-up',
  templateUrl: './sign-up.component.html'
})
export class SignUpComponent {
  public env = environment;

  formGroup: FormGroup = this._formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(8)]],
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    company: [null]
  });

  priceId: string | undefined = undefined;

  showForm = false;

  public passwordFieldVisibility = false;

  constructor(
    private signUpService: SignUpService,
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private subscriptionService: SubscriptionService,
    private stripeService: StripeService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder
  ) {
    this.route.params.subscribe({
      next: params => {
        this.priceId = params['product']
          ? 'price_' + params['product']
          : undefined;
      }
    });

    if (this.authorizationService.isAuth()) {
      this.authService.refreshLogIn().subscribe({
        next: () => {
          if (this.authorizationService.isSubscribed() || !this.priceId) {
            this._goToTable();
          } else {
            this._processUserFlow();
          }
        },
        error: () => {
          this.spinner?.hide('global');
          this.showForm = true;
        }
      });
    } else {
      this.spinner?.hide('global');
      this.showForm = true;
    }
  }

  private _processUserFlow() {
    if (this.authorizationService.isSubscriptionSuspended()) {
      this.router.navigate(['/authorization/unauthorized-access']);
      this.spinner?.hide('global');
      this.snackBarService.snack({
        type: SnackMessageStatusEnum.ERROR,
        message:
          'Your subscription has been suspended. Please contact us for more details.'
      });
    } else if (this.priceId && !this.authorizationService.isSubscribed()) {
      this._onRegisterClient(this.priceId);
    }
  }

  private _onRegisterClient(priceId: string) {
    this.subscriptionService.createAccountSubscription(priceId).subscribe({
      next: res => {
        if (res.subscription) {
          this._createCheckoutSession(
            res.accountId,
            res.subscription.customerId,
            priceId
          );
        }
      },
      complete: () => {
        if (!this.priceId) {
          this._doLogin();
        }
      },
      error: err => {
        this.spinner?.hide('global');
        this.snackBarService.snack({
          type: SnackMessageStatusEnum.ERROR,
          message: err.message
        });
      }
    });
  }

  onSubmitForm() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.spinner?.show('global');

    this.signUpService
      .signUp({
        email: this.formGroup.controls['email'].value.toLowerCase(),
        password: this.formGroup.controls['password'].value,
        firstName: this.formGroup.controls['firstName'].value,
        lastName: this.formGroup.controls['lastName'].value,
        company: this.formGroup.controls['company'].value,
        priceId: this.priceId || undefined
      })
      .subscribe({
        next: res => {
          if (this.priceId) {
            this._login().subscribe(() => {
              this._createCheckoutSession(
                res.accountId,
                res.subscription.customerId,
                this.priceId!
              );
            });
          } else {
            this._doLogin();
          }
        },
        error: err => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.ERROR,
            message:
              err.message ||
              'There was a problem with your registration process. Please review you information and proceed again.'
          });
        }
      });
  }

  private _login() {
    return this.authService.login(
      this.formGroup.controls['email'].value.toLowerCase(),
      this.formGroup.controls['password'].value
    );
  }

  private _doLogin() {
    this._login().subscribe({
      complete: () => this._goToTable(),
      error: () => {
        this.router.navigate(['/authorization/login']);
        this.spinner?.hide('global');
        this.snackBarService.snack({
          type: SnackMessageStatusEnum.ERROR,
          message: 'Authentication failure'
        });
      }
    });
  }

  private _createCheckoutSession(
    accountId: string,
    customerId: string,
    priceId: string
  ) {
    this.subscriptionService
      .createCheckoutSession({
        accountId: accountId,
        priceId: priceId,
        customerId: customerId
      })
      .pipe(
        switchMap(res =>
          this.stripeService.redirectToCheckout({
            sessionId: res.sessionId
          })
        )
      )
      .subscribe({
        error: err => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.ERROR,
            message: err.message
          });
          console.error(err);
        }
      });
  }

  private _goToTable() {
    this.router.navigate(['/data-sheet']);
  }

  togglePasswordField() {
    this.passwordFieldVisibility = !this.passwordFieldVisibility;
  }
}
