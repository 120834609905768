<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div class="auth mb-5" [style]="{ width: '22rem' }">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>
    <div class="flex flex-col items-center justify-center flex-1">
      <p>
        Unauthorized application access.<br />
        Please
        <a class="underline" href="mailto:hi@multiples.finance">contact us </a
        >for further assistance.
      </p>
    </div>
  </div>
  <mul-footer></mul-footer>
</main>
