import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResolveFn } from '@angular/router';
import { UIOptionType } from '../../data/type/filter/UIOption.type';
import { IndustryThemeService } from '../industry-theme.service';

@Injectable({ providedIn: 'root' })
export class IndustryThemeFilterResolverService {
  constructor(private industryThemeService: IndustryThemeService) {}

  resolve() {
    return this.industryThemeService.getAll$();
  }
}

export const industryThemeFilterResolver: ResolveFn<Observable<
  UIOptionType[]
>> = () => inject(IndustryThemeFilterResolverService).resolve();
