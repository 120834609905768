import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { StoredGridFilter } from '../../../data/interface/stored-grid/stored-grid.interface';
import { StoredGridService } from '../../../service/stored-grid.service';
import { SnackBarService } from '../../../../uicommon/service/snack-bar.service';
import { DialogService } from '../../../../uicommon/service/dialog.service';
import { DialogStatusEnum } from '../../../../uicommon/data/enum/dialogStatus.enum';
import { SnackMessageStatusEnum } from '../../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'mul-stored-grid-item',
  templateUrl: './stored-grid-item.component.html'
})
export class StoredGridItemComponent {
  @Input() item!: StoredGridFilter;
  @Output() loadStoredGridFilterEvent = new EventEmitter<StoredGridFilter>();
  @ViewChild('nameInput') input!: ElementRef;

  editMode = false;

  constructor(
    private readonly storedGridFilterService: StoredGridService,
    private readonly snackBarService: SnackBarService,
    private readonly dialogService: DialogService
  ) {}

  load() {
    this.loadStoredGridFilterEvent.emit(<StoredGridFilter>this.item);
  }

  deleteDialog() {
    this.dialogService.show({
      title: 'Delete confirmation',
      text: `Do you really wish to permanently delete the "${this.item.name}" saved search?`,
      actionLabel: 'Delete',
      type: DialogStatusEnum.ALERT,
      action: () => {
        this._delete();
      }
    });
  }

  private _delete() {
    this.storedGridFilterService.delete$(<string>this.item.id).subscribe(() => {
      const savedStoredFilters = this.storedGridFilterService.storedGridStore
        .savedStoredGrids;

      this.storedGridFilterService.storedGridStore.savedStoredGrids = savedStoredFilters.filter(
        it => it.id !== this.item.id
      );
    });
  }

  update() {
    const name = this.input.nativeElement.value;

    if (name) {
      this.storedGridFilterService
        .updateDefinition$(<string>this.item.id, name)
        .subscribe({
          next: val => {
            this.storedGridFilterService.storedGridStore.updateSavedStoredGrids(
              val
            );

            this.snackBarService.snack({
              type: SnackMessageStatusEnum.SUCCESS,
              message: `The saved search has been updated to '${name}'.`
            });
          }
        });

      this.editMode = false;
    }
  }
}
