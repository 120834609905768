<div #summary class=" flex justify-between sticky-left">
  <span class="ml-2">
    Showing {{ recordCount }} companies. Absolute values displayed in millions
    unless stated otherwise.

    <span
      *ngIf="
        !this.authorizationService.isSubscribed() &&
        this.authorizationService.isAuth()
      "
    >
      <mul-upgrade-action
        id="summary-bar-upgrade-action"
        [label]="
          this.authorizationService.isTrialPeriodAllowed()
            ? 'Start 7-Day Trial'
            : 'Upgrade Plan'
        "
      ></mul-upgrade-action>
      to access all companies and platform features.
    </span>
  </span>
  <span class="mr-2">
    <mul-data-paginator></mul-data-paginator>
  </span>
</div>
