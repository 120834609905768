import { Injectable } from '@angular/core';
import { GridMutatorService } from '../../mutator/grid-mutator.service';
import { ApiService } from '../../../../common/service/api.service';
import { Observable } from 'rxjs';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { DealDataGridFilterService } from '../../deal-data-grid-filter.service';
import { DealDataFilter } from '../../../data/type/filter/deal/deal-data-filter.type';

@Injectable({
  providedIn: 'root'
})
export class DealDataExportService {
  constructor(
    private gridFilterService: DealDataGridFilterService,
    private gridMutatorService: GridMutatorService,
    private api: ApiService
  ) {}

  export$(viewsIds: string): Observable<Blob> {
    const filter: DealDataFilter = this.gridFilterService.dealDataFilterStore
      .gridFilter;

    const mutation = this.gridMutatorService.gridMutatorStorage.gridMutation;

    const url = ApiRoutes.DEAL_GRID_EXPORT.valueOf();

    return this.api.httpPost$<Blob>(
      url,
      { views: viewsIds.split(','), filter: filter, mutation: mutation },
      { responseType: 'blob' as 'json' }
    );
  }
}
