import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { DealInfoFilterType } from '../../../data/type/filter/deal/deal-info-filter.type';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealsTypeEnum } from '../../../data/enum/grid/deals-type.enum';
import { DateTime } from 'luxon';
import { Datepicker, initFlowbite } from 'flowbite';

interface DatepickerChangeEvent extends Event {
  detail: {
    date: Date;
  };
}

@Component({
  selector: 'mul-deal-info-filter',
  templateUrl: './deal-info-filter.component.html'
})
export class DealInfoFilterComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Output() dataMutationEventEmitter: EventEmitter<
    DealInfoFilterType
  > = new EventEmitter();

  private dateMinPicker: Datepicker | undefined;
  private dateMaxPicker: Datepicker | undefined;

  @Input() filterSelection: DealInfoFilterType = {} as DealInfoFilterType;

  filterForm: FormGroup = this.formBuilder.group({
    dateMin: [null, [Validators.pattern(/^\d{2}\/\d{2}\/\d{4}$/)]],
    dateMax: [null, [Validators.pattern(/^\d{2}\/\d{2}\/\d{4}$/)]],
    enterpriseValueMin: '',
    enterpriseValueMax: '',
    evRevenueMin: '',
    evRevenueMax: '',
    evEbitdaMin: '',
    evEbitdaMax: '',
    strategicMaType: true,
    financialBuyOutGroup: true,
    peAddOnType: true,
    peBuyOutType: true
  });

  protected readonly DealsTypeEnum = DealsTypeEnum;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngAfterViewInit(): void {
    const dateMinEl = document.getElementById('dateMin');
    dateMinEl!.addEventListener('changeDate', event => {
      const eventDate = (event as DatepickerChangeEvent).detail.date;
      const formattedDate = DateTime.fromJSDate(eventDate).toFormat(
        'dd/LL/yyyy'
      );

      this.filterForm.get('dateMin')?.patchValue(formattedDate);
      this.filterSelection.date.min = DateTime.fromJSDate(eventDate).toMillis();

      this.onFormItemChange();
    });
    this.dateMinPicker = new Datepicker(dateMinEl, {
      autohide: true,
      format: 'dd/mm/yyyy'
    });

    const dateMaxEl = document.getElementById('dateMax');
    dateMaxEl!.addEventListener('changeDate', event => {
      const eventDate = (event as DatepickerChangeEvent).detail.date;
      const formattedDate = DateTime.fromJSDate(eventDate).toFormat(
        'dd/LL/yyyy'
      );

      this.filterForm.get('dateMax')?.patchValue(formattedDate);
      this.filterSelection.date.max = DateTime.fromJSDate(eventDate).toMillis();

      this.onFormItemChange();
    });
    this.dateMaxPicker = new Datepicker(dateMaxEl, {
      autohide: true,
      format: 'dd/mm/yyyy'
    });
  }

  ngOnInit(): void {
    initFlowbite();
  }

  ngOnChanges(): void {
    if (this.filterSelection.date?.min) {
      this.filterForm
        .get('dateMin')
        ?.patchValue(
          DateTime.fromMillis(this.filterSelection.date.min).toFormat(
            'dd/LL/yyyy'
          )
        );
    } else {
      this.filterForm.get('dateMin')?.patchValue('');
    }

    if (this.filterSelection.date?.max) {
      this.filterForm
        .get('dateMax')
        ?.patchValue(
          DateTime.fromMillis(this.filterSelection.date.max).toFormat(
            'dd/LL/yyyy'
          )
        );
    } else {
      this.filterForm.get('dateMax')?.patchValue('');
    }

    this.filterForm
      .get('enterpriseValueMin')
      ?.patchValue(this.filterSelection.enterpriseValue?.min);

    this.filterForm
      .get('enterpriseValueMax')
      ?.patchValue(this.filterSelection.enterpriseValue?.max);

    this.filterForm
      .get('evRevenueMin')
      ?.patchValue(this.filterSelection.dealEnterpriseValueRevenue?.min);

    this.filterForm
      .get('evRevenueMax')
      ?.patchValue(this.filterSelection.dealEnterpriseValueRevenue?.max);

    this.filterForm
      .get('evEbitdaMin')
      ?.patchValue(this.filterSelection.dealEnterpriseValueEbitda?.min);

    this.filterForm
      .get('evEbitdaMax')
      ?.patchValue(this.filterSelection.dealEnterpriseValueEbitda?.max);
  }

  onFormItemChange() {
    this.filterSelection.enterpriseValue = {
      min: this.filterForm.get('enterpriseValueMin')?.value,
      max: this.filterForm.get('enterpriseValueMax')?.value
    };

    this.filterSelection.dealEnterpriseValueRevenue = {
      ...this.filterSelection.dealEnterpriseValueRevenue,
      min: this.filterForm.get('evRevenueMin')?.value,
      max: this.filterForm.get('evRevenueMax')?.value
    };

    this.filterSelection.dealEnterpriseValueEbitda = {
      ...this.filterSelection.dealEnterpriseValueEbitda,
      min: this.filterForm.get('evEbitdaMin')?.value,
      max: this.filterForm.get('evEbitdaMax')?.value
    };

    this.dataMutationEventEmitter.emit(this.filterSelection);

    console.log(this.filterSelection);
  }

  setRevenueExcludeNonReportedItems(event: boolean) {
    this.filterSelection.dealEnterpriseValueRevenue.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setEbitdaExcludeNonReportedItems(event: boolean) {
    this.filterSelection.dealEnterpriseValueEbitda.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setStrategicMA() {
    if (this.filterForm.get('strategicMaType')?.value) {
      this.setBuyerType(DealsTypeEnum.STRATEGIC_MA);
    } else {
      this.unsetBuyerType(DealsTypeEnum.STRATEGIC_MA);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setFinancialBuyOutGroup() {
    this.filterForm
      .get('peAddOnType')
      ?.patchValue(this.filterForm.get('financialBuyOutGroup')?.value);
    this.filterForm
      .get('peBuyOutType')
      ?.patchValue(this.filterForm.get('financialBuyOutGroup')?.value);

    this.setPeAddOn();
    this.setPeBuyOut();
  }

  setPeAddOn() {
    if (this.filterForm.get('peAddOnType')?.value) {
      this.filterForm.get('financialBuyOutGroup')?.patchValue(true);
      this.setBuyerType(DealsTypeEnum.PE_ADD_ON);
    } else {
      this.unsetBuyerType(DealsTypeEnum.PE_ADD_ON);
    }

    if (
      !this.filterForm.get('peAddOnType')?.value &&
      !this.filterForm.get('peBuyOutType')?.value
    ) {
      this.filterForm.get('financialBuyOutGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setPeBuyOut() {
    if (this.filterForm.get('peBuyOutType')?.value) {
      this.filterForm.get('financialBuyOutGroup')?.patchValue(true);
      this.setBuyerType(DealsTypeEnum.PE_BUYOUT);
    } else {
      this.unsetBuyerType(DealsTypeEnum.PE_BUYOUT);
    }

    if (
      !this.filterForm.get('peAddOnType')?.value &&
      !this.filterForm.get('peBuyOutType')?.value
    ) {
      this.filterForm.get('financialBuyOutGroup')?.patchValue(false);
    }

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  private setBuyerType(type: DealsTypeEnum) {
    const index = this.filterSelection.dealsTypes.indexOf(type);

    if (index < 0) this.filterSelection.dealsTypes.push(type);
  }

  private unsetBuyerType(type: DealsTypeEnum) {
    const index = this.filterSelection.dealsTypes.indexOf(type);

    if (index >= 0) this.filterSelection.dealsTypes.splice(index, 1);
  }
}
