<span
  (click)="extend = true"
  (mouseover)="extend = true"
  *ngFor="let tag of recordElement; let i = index"
  [hidden]="i >= filterDisplayLimit || extend || tag === ''"
  class="pill"
>
  <div class="{{ recordElement.length > 1 ? 'short' : 'single' }}">
    {{ tag }}
  </div>
</span>

<div
  (click)="extend = false"
  (mouseleave)="extend = false"
  [hidden]="!extend"
  class="list extended"
>
  <span *ngFor="let tag of recordElement" class="pill">
    {{ tag }}
  </span>
</div>
