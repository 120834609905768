import { DealsTypeEnum } from '../../../enum/grid/deals-type.enum';

export type DealInfoFilterType = {
  date: {
    min?: number | null;
    max?: number | null;
  };
  enterpriseValue: {
    min: number | null;
    max: number | null;
  };
  dealEnterpriseValueRevenue: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
  dealEnterpriseValueEbitda: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
  dealsTypes: DealsTypeEnum[];
};

export class DealInfoFilterFactory {
  static generate() {
    return <DealInfoFilterType>{
      date: {
        min: null,
        max: null
      },
      enterpriseValue: {
        min: null,
        max: null
      },
      dealEnterpriseValueRevenue: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      },
      dealEnterpriseValueEbitda: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      },
      dealsTypes: [
        DealsTypeEnum.STRATEGIC_MA,
        DealsTypeEnum.PE_ADD_ON,
        DealsTypeEnum.PE_BUYOUT
      ]
    };
  }
}
