import { inject, Injectable } from '@angular/core';
import { StoredGridService } from '../stored-grid.service';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { NavigationStore } from '../../../navigation/service/store/navigation.store';
import { DataType } from '../../../navigation/data/emun/data-type';
import { StoredGridEnum } from '../../data/enum/stored-grid/stored-grid.enum';

@Injectable({ providedIn: 'root' })
export class StoredPublicGridFilterInterceptorService {
  constructor(
    private readonly storedGridFilterService: StoredGridService,
    private readonly navigationStore: NavigationStore
  ) {}

  resolve(storedGridFilterId: string) {
    this.storedGridFilterService.findById$(storedGridFilterId).subscribe({
      next: val => {
        const dataType =
          val.type === StoredGridEnum.DEAL ? DataType.DEALS : DataType.PUBLIC;

        const navigationState = this.navigationStore.navigation?.find(
          it => it.dataType === dataType
        );

        if (navigationState) {
          this.navigationStore.navigationState = navigationState;
          this.storedGridFilterService.applyStoredFilter(val);
        }
      }
    });
  }
}

export const storedGridFilterInterceptor: ResolveFn<void> = (
  route: ActivatedRouteSnapshot
) =>
  inject(StoredPublicGridFilterInterceptorService).resolve(
    route.params['storedGridFilterId']
  );
