<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div [style]="{ width: '22rem' }" class="auth mb-5">
    <h1 class="flex flex-col items-center mb-5">
      <a class="{{ priceId ? 'pro' : '' }}" href="{{ env.site_url }}"></a>
    </h1>

    <form
      (ngSubmit)="onSubmitForm()"
      [formGroup]="formGroup"
      class="flex flex-col items-center justify-center flex-1"
    >
      <div class="flex flex-col w-full mb-5">
        <label for="firstname">First Name</label>
        <input formControlName="firstName" id="firstName" type="text" />
        <small
          *ngIf="
            formGroup.controls['firstName'].touched &&
            formGroup.controls['firstName']?.hasError('required')
          "
          id="firstName-required"
        >
          Please provide your name
        </small>
      </div>

      <div class="flex flex-col w-full mb-5">
        <label for="lastName">Last Name</label>
        <input formControlName="lastName" id="lastName" type="text" />
        <small
          *ngIf="
            formGroup.controls['lastName'].touched &&
            formGroup.controls['lastName']?.hasError('required')
          "
          id="lastName-required"
        >
          Please provide your last name
        </small>
      </div>

      <div class="flex flex-col w-full mb-5">
        <label for="company">Company</label>
        <input formControlName="company" id="company" type="text" />
      </div>

      <div class="flex flex-col w-full mb-5">
        <label for="email">Email</label>
        <input formControlName="email" id="email" type="email" />
        <small
          *ngIf="
            formGroup.controls['email'].touched &&
            formGroup.controls['email']?.hasError('required')
          "
          id="email-required"
        >
          Please provide an email address
        </small>
        <small
          *ngIf="
            formGroup.controls['email'].touched &&
            formGroup.controls['email']?.hasError('email')
          "
          id="email-valid"
        >
          Please provide a valid email address
        </small>
      </div>

      <div class="flex flex-col w-full mb-5 group">
        <label for="password">Password</label>
        <div
          class="flex flex-row align-middle password group-focus-within:ring-1 group-focus-within:ring-primary-300 group-focus-within:border-primary-300"
        >
          <input
            [type]="passwordFieldVisibility ? 'text' : 'password'"
            class="group-focus-within:focus-0 group-focus-within:outline-none"
            formControlName="password"
            id="password"
          />
          <span
            (click)="passwordFieldVisibility = !passwordFieldVisibility"
            class="fa-regular fa-eye {{
              passwordFieldVisibility ? 'fa-eye-slash' : 'fa-eye'
            }}"
          >
          </span>
        </div>
        <small
          *ngIf="
            formGroup.controls['password'].touched &&
            formGroup.controls['password']?.hasError('required')
          "
          id="password-required"
        >
          Please provide a user account password
        </small>
        <small
          *ngIf="
            formGroup.controls['password'].touched &&
            formGroup.controls['password']?.hasError('minlength')
          "
          id="password-minLength"
        >
          Password must be at least 8 characters long
        </small>
      </div>

      <div class="flex flex-row justify-around links">
        <a class="underline" id="goToLogin" routerLink="/authorization/login"
          >Already have an account?</a
        >
        <a
          class="underline"
          id="goToAccountRecovery"
          routerLink="/authorization/account-recovery"
          >Forgot your password?</a
        >
      </div>

      <button id="sign-up-action" type="submit">
        {{ priceId ? "Proceed to checkout" : "Sign Up" }}
      </button>
    </form>

    <p>
      By signing up, you consent to agree to Flow Partners'
      <a href="https://flowpartners.io/terms" target="_blank"
        >terms and conditions</a
      >
      and agree to receive communications from us. You can unsubscribe at
      anytime. For more information please review our
      <a href="https://flowpartners.io/privacy" target="_blank"
        >privacy policy</a
      >.
    </p>
  </div>
  <mul-footer></mul-footer>
</main>
