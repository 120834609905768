import { Injectable, OnDestroy } from '@angular/core';
import { DataSheetStore } from './store/data-sheet.store';
import { GridData } from '../data/type/organization/grid-data.type';
import { FilterStorage } from './store/filter.storage';
import { DataPaginationStore } from './store/data-pagination.store';
import { FilterRefEnum } from '../data/enum/grid/filter-ref.enum';

import { PublicDataFilterStore } from './store/public-data-filter.store';
import {
  FinancialFilter,
  PublicDataFilter,
  PublicDataFilterFactory
} from '../data/type/filter/public/public-data-filter.type';
import { FilterTriggerEvent } from './FilterTriggerEvent';
import { StoredPublicGridFilterFactory } from '../data/interface/stored-grid/stored-grid.interface';
import { StoredGridStore } from './store/stored-grid.store';
import { GridMutatorStore } from './mutator/grid-mutator.store';
import { MutationFactory } from '../data/type/mutator/data-mutator.type';
import { DataSortingService } from './data-sorting.service';
import { Subscription } from 'rxjs';
import { DataType } from '../../navigation/data/emun/data-type';
import { NavigationStore } from '../../navigation/service/store/navigation.store';

@Injectable({
  providedIn: 'root'
})
export class PublicDataGridFilterService implements OnDestroy {
  private gridFilterSubscription: Subscription = new Subscription();

  constructor(
    public readonly gridFilterStore: PublicDataFilterStore,
    public readonly filterStorageService: FilterStorage,
    public readonly dataSheetStorageService: DataSheetStore,
    private readonly dataPaginationStoreService: DataPaginationStore,
    private readonly dataSortingService: DataSortingService,
    private readonly mutatorStore: GridMutatorStore,
    private filterTriggerEvent: FilterTriggerEvent,
    private savedStoredGridStore: StoredGridStore,
    private readonly navigationStore: NavigationStore
  ) {
    this.gridFilterSubscription = this.gridFilterStore.gridFilter$.subscribe(
      async val => {
        const data = this.dataSheetStorageService.gridData;

        if (data.length > 0) {
          console.debug('PUBLIC FILTERING: gridFilter$', val);
          await this.__processFilter(data, val);
        }
      }
    );

    this.dataSheetStorageService.gridData$.subscribe(async val => {
      if (
        val.length > 0 &&
        this.navigationStore.navigationState?.dataType === DataType.PUBLIC
      ) {
        const filter = this.gridFilterStore.gridFilter;
        console.debug('FILTERING: gridData$', val.length);

        await this.__processFilter(val, filter);
      }
    });

    this.filterTriggerEvent.triggerPublicFilter$.subscribe(async () => {
      const data = this.dataSheetStorageService.gridData;
      const filter = this.gridFilterStore.gridFilter;
      console.debug('FILTERING: triggerFilter$');

      await this.__processFilter(data, filter);
    });

    this.mutatorStore.gridMutation$.subscribe(() => {
      this._getFilterCount();
    });
  }

  ngOnDestroy(): void {
    this.gridFilterSubscription.unsubscribe();
  }

  clearFilters() {
    this.gridFilterStore.gridFilter = PublicDataFilterFactory.generate();
    this.savedStoredGridStore.activeSavedStoredGrid = StoredPublicGridFilterFactory.generate();
    this.mutatorStore.gridMutation = MutationFactory.generate();
    this.dataSortingService.gridSortStore.sort = {
      direction: -1,
      column: this.dataSheetStorageService.fieldMap.get(FilterRefEnum.EV)!
    };

    this._getFilterCount();
  }

  private async __processFilter(data: GridData[], filter: PublicDataFilter) {
    data =
      filter.tags.length > 0 ? this._filterByTags(data, filter.tags) : data;

    data =
      filter.organizations.length > 0
        ? filter.tags.length > 0
          ? this._attachSimilarOrganizations(data, filter.organizations)
          : this._filterByOrganizations(data, filter.organizations)
        : data;

    data =
      filter.geographies.length > 0
        ? this._filterByGeographies(data, filter.geographies)
        : data;

    data =
      filter.ev.max !== null || filter.ev.min !== null
        ? this._filterByEnterpriseValue(data, filter.ev)
        : data;

    data =
      (filter.revenue.max !== null || filter.revenue.min !== null) &&
      filter.revenue.index
        ? this._filterByRevenue(data, filter.revenue)
        : data;

    data =
      (filter.revenueGrowth.max !== null ||
        filter.revenueGrowth.min !== null) &&
      filter.revenueGrowth.index
        ? this._filterByRevenueGrowth(data, filter.revenueGrowth)
        : data;

    data =
      (filter.ebitda.max !== null || filter.ebitda.min !== null) &&
      filter.ebitda.index
        ? this._filterByEbitda(data, filter.ebitda)
        : data;

    console.log('filter.profitable:' + filter.profitable);
    data = filter.profitable ? this._filterByEbitdaProfitable(data) : data;

    data =
      filter.organizationRows.length > 0
        ? this._attachRows(data, filter.organizationRows)
        : data;

    this.dataSheetStorageService.gridDisplayData = data;

    this.dataPaginationStoreService.page = 1;
    this.dataPaginationStoreService.length = data.length;

    this.gridFilterStore.filterCount = this._getFilterCount();

    this.dataSortingService.gridSortStore.trigger = true;
  }

  private _getFilterCount() {
    let count;

    count =
      this.gridFilterStore.verticals?.length +
      this.gridFilterStore.organizations?.length +
      this.gridFilterStore.geographies?.length +
      this.gridFilterStore.organizationRows?.length;

    if (this.gridFilterStore.enterpriseValue?.max) ++count;
    if (this.gridFilterStore.enterpriseValue?.min) ++count;

    if (this.gridFilterStore.revenue?.max) ++count;
    if (this.gridFilterStore.revenue?.min) ++count;

    if (this.gridFilterStore.revenueGrowth?.max) ++count;
    if (this.gridFilterStore.revenueGrowth?.min) ++count;

    if (this.gridFilterStore.ebitda?.max) ++count;
    if (this.gridFilterStore.ebitda?.min) ++count;

    if (this.gridFilterStore.profitable) ++count;

    if (this.mutatorStore.gridMutation.statusOff.length > 0)
      count = count + this.mutatorStore.gridMutation.statusOff.length;

    this.gridFilterStore.filterCount = count;

    return count;
  }

  private _filterByTags(data: GridData[], items: string[]): GridData[] {
    const fldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.VERTICALS)
    );

    return data.filter(it => {
      const itemTags = <string[]>it[fldRef]
        ?.toString()
        .split(';')
        .map(t => t.trim());

      return items.filter(tag => itemTags?.includes(tag)).length > 0;
    });
  }

  private _filterByOrganizations(
    data: GridData[],
    items: string[]
  ): GridData[] {
    const fldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.VERTICALS)
    );

    const itemsTags = [
      ...new Set<string>(
        this.filterStorageService.organizations
          .filter(it => items.includes(it.ref))
          .map(it => (it.meta ? it.meta.map(t => t.trim()) : []))
          .flat()
      )
    ];

    return data.filter(it => {
      const tags = <string[]>it[fldRef]
        ?.toString()
        .split(';')
        .map(t => t.trim());

      return tags.filter(tag => itemsTags.includes(tag.trim())).length > 0;
    });
  }

  private _attachSimilarOrganizations(
    data: GridData[],
    items: string[]
  ): GridData[] {
    const verticalFldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.VERTICALS)
    );

    const skuFldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.SKU)
    );

    const itemsTags = [
      ...new Set<string>(
        this.filterStorageService.organizations
          .filter(it => items.includes(it.ref))
          .map(it => (it.meta ? it.meta.map(t => t.trim()) : []))
          .flat()
      )
    ];

    const selectedCompanyRows = [
      ...this.dataSheetStorageService.gridData
    ].filter(it => {
      const tags = <string[]>it[verticalFldRef]
        ?.toString()
        .split(';')
        .map(t => t.trim());

      return tags.filter(tag => itemsTags.includes(tag.trim())).length > 0;
    });

    const reducedSelectedCompanyRows = selectedCompanyRows.filter(
      it => data.map(d => d[skuFldRef])?.includes(it[skuFldRef]) === false
    );

    return [...data, ...reducedSelectedCompanyRows];
  }

  private _attachRows(data: GridData[], items: string[]) {
    const skuFldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.SKU)
    );

    let rows;
    const currentSkus = data.map(it => it[skuFldRef]);

    if (this._getFilterCount() === items.length) {
      rows = this.dataSheetStorageService.gridData.filter(it =>
        items.includes(<string>it[skuFldRef])
      );
    } else {
      const allowedItems = items.filter(it => !currentSkus.includes(it));
      rows = this.dataSheetStorageService.gridData.filter(it =>
        allowedItems.includes(<string>it[skuFldRef])
      );
    }

    return this._getFilterCount() === items.length ? rows : [...data, ...rows];
  }

  private _filterByGeographies(data: GridData[], items: string[]): GridData[] {
    const fldRef = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.COUNTRY)
    );

    return data.filter(it => {
      const country = <string>it[fldRef];

      return items.filter(x => country?.includes(x)).length > 0;
    });
  }

  private _filterByEnterpriseValue(
    data: GridData[],
    filter: FinancialFilter
  ): GridData[] {
    const ref = <string>(
      this.dataSheetStorageService.fieldMap.get(FilterRefEnum.EV)
    );

    return this._filterByFinancialFilterValue(
      ref,
      {
        min: <number>filter?.min || null,
        max: <number>filter?.max || null
      },
      data
    );
  }

  private _filterByRevenue(data: GridData[], filter: FinancialFilter) {
    const ref = <string>(
      this.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.REVENUES + <string>filter.index
      )
    );

    return this._filterByFinancialFilterValue(
      ref,
      {
        min: <number>filter?.min || null,
        max: <number>filter?.max || null
      },
      data
    );
  }

  private _filterByRevenueGrowth(data: GridData[], filter: FinancialFilter) {
    const field = !isNaN(Number(filter.index))
      ? FilterRefEnum.EV_REVENUE_GROWTH + <string>filter.index
      : <string>filter.index;

    const ref = <string>this.dataSheetStorageService.fieldMap.get(field);

    return this._filterByFinancialFilterValue(
      ref,
      {
        min: <number>filter?.min || null,
        max: <number>filter?.max || null
      },
      data
    );
  }

  private _filterByEbitda(data: GridData[], filter: FinancialFilter) {
    const ref = <string>(
      this.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.EBITDA + <string>filter.index
      )
    );

    return this._filterByFinancialFilterValue(
      ref,
      {
        min: <number>filter?.min || null,
        max: <number>filter?.max || null
      },
      data
    );
  }

  private _filterByEbitdaProfitable(data: GridData[]) {
    return data.filter(it => {
      const fldRef = <string>(
        this.dataSheetStorageService.fieldMap.get(FilterRefEnum.EBITDA_LTM)
      );

      return <number>it[fldRef] > 0;
    });
  }

  private _filterByFinancialFilterValue(
    ref: string,
    filter: { min: number | null; max: number | null },
    data: GridData[]
  ) {
    return data
      .filter(it => (filter.min ? <number>it[ref] >= filter.min : true))
      .filter(it => (filter.max ? <number>it[ref] <= filter.max : true));
  }
}
