export type PublicDataFilter = {
  tags: string[];
  organizations: string[];
  organizationRows: string[];
  geographies: string[];
  ev: FinancialFilter;
  revenue: FinancialFilter;
  revenueGrowth: FinancialFilter;
  ebitda: FinancialFilter;
  profitable: boolean;
};

export type FinancialValueRange = {
  min: number | null;
  max: number | null;
};

export type FinancialFilter = {
  min: number | null;
  max: number | null;
  index: string | null;
};

export class FinancialFilterFactory {
  static generate() {
    return <FinancialFilter>{
      min: null,
      max: null,
      index: null
    };
  }
}

export class PublicDataFilterFactory {
  static generate() {
    return <PublicDataFilter>{
      tags: [],
      organizations: [],
      organizationRows: [],
      geographies: [],
      ev: FinancialFilterFactory.generate(),
      revenue: FinancialFilterFactory.generate(),
      revenueGrowth: FinancialFilterFactory.generate(),
      ebitda: FinancialFilterFactory.generate(),
      profitable: false
    };
  }
}
