<div class="relative flex justify-between items-center">
  <div class="long-text">{{ record[field.ref] }}</div>
  <i
    (click)="isOpen = !isOpen"
    (mouseover)="isOpen = !isOpen"
    class="fa-solid fa-expand cursor-pointer ml-1"
  ></i>

  <div
    *ngIf="isOpen"
    class="floating-box"
    (mouseout)="isOpen = !isOpen"
    (click)="isOpen = !isOpen"
  >
    {{ record[field.ref] }}
  </div>
</div>
