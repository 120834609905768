import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResolveFn } from '@angular/router';
import { StoredGridFilter } from '../../data/interface/stored-grid/stored-grid.interface';
import { StoredGridService } from '../stored-grid.service';

@Injectable({ providedIn: 'root' })
export class StoredGridResolverService {
  constructor(private storedGridService: StoredGridService) {}

  resolve() {
    return this.storedGridService.findAllBySaved$();
  }
}

export const storedGridResolver: ResolveFn<Observable<
  StoredGridFilter[]
>> = () => inject(StoredGridResolverService).resolve();
