import { Injectable } from '@angular/core';
import { PublicDataFilter } from '../../../data/type/filter/public/public-data-filter.type';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { PublicDataGridFilterService } from '../../public-data-grid-filter.service';
import { ApiService } from '../../../../common/service/api.service';
import { GridMutatorService } from '../../mutator/grid-mutator.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicDataExportService {
  constructor(
    private gridFilterService: PublicDataGridFilterService,
    private gridMutatorService: GridMutatorService,
    private api: ApiService
  ) {}

  export$(viewsIds: string): Observable<Blob> {
    const filter: PublicDataFilter = this.gridFilterService.gridFilterStore
      .gridFilter;

    const mutation = this.gridMutatorService.gridMutatorStorage.gridMutation;

    const url = ApiRoutes.DATA_GRID_EXPORT.valueOf();

    return this.api.httpPost$<Blob>(
      url,
      { views: viewsIds.split(','), filter: filter, mutation: mutation },
      { responseType: 'blob' as 'json' }
    );
  }
}
