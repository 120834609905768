import { Component, Input, OnInit } from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';

@Component({
  selector: 'mul-cell-text-list',
  templateUrl: './cell-text-list.component.html'
})
export class CellTextListComponent implements OnInit {
  @Input() record!: GridData;
  @Input() field!: ColumnField;

  filterDisplayLimit = 2;
  extend = false;

  public recordElement: string[] = [];

  ngOnInit(): void {
    const arr = this.record[this.field.ref]?.toString().split(';');

    this.recordElement = <string[]>arr || [];
  }
}
