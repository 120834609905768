<span
  *ngFor="let field of fields"
  [ngStyle]="{ 'width.px': field.width }"
  class="block {{ field.type ? field.type.toLowerCase() : 'number' }}"
>
  <mul-cell-switch
    *ngIf="isFieldType(field.type, enumType.SWITCH)"
    [field]="field"
    [record]="record"
    class="organization-status"
  ></mul-cell-switch>

  <mul-cell-image
    *ngIf="isFieldType(field.type, enumType.IMAGE)"
    [field]="field"
    [record]="record"
  >
  </mul-cell-image>

  <span
    *ngIf="isFieldType(field.type, enumType.ICON)"
    class="flag fi fi-{{ record[field.ref]?.toString()?.toLowerCase() }} fis"
  >
  </span>

  <mul-cell-number
    *ngIf="isFieldType(field.type, enumType.NUMBER) || !field.type"
    [field]="field"
    [record]="record"
  >
  </mul-cell-number>

  <mul-cell-decimal
    *ngIf="isFieldType(field.type, enumType.DECIMAL)"
    [field]="field"
    [record]="record"
  >
  </mul-cell-decimal>

  <mul-cell-year
    *ngIf="isFieldType(field.type, enumType.YEAR)"
    [field]="field"
    [record]="record"
  >
  </mul-cell-year>

  <mul-cell-number
    *ngIf="isFieldType(field.type, enumType.LONG_NUMBER)"
    [field]="field"
    [record]="record"
  >
  </mul-cell-number>

  <span *ngIf="isFieldType(field.type, enumType.STRING)">
    {{ record[field.ref] }}
  </span>

  <span *ngIf="isFieldType(field.type, enumType.SHORT_STRING)">
    {{ record[field.ref] }}
  </span>

  <span *ngIf="isFieldType(field.type, enumType.MID_STRING)">
    {{ record[field.ref] }}
  </span>

  <mul-cell-long-string
    *ngIf="isFieldType(field.type, enumType.LONG_STRING)"
    [field]="field"
    [record]="record"
  >
  </mul-cell-long-string>

  <mul-cell-text
    *ngIf="isFieldType(field.type, enumType.TEXT) && record[field.ref] !== null"
    [field]="field"
    [record]="record"
  >
  </mul-cell-text>

  <mul-cell-list
    *ngIf="isFieldType(field.type, enumType.LIST)"
    [field]="field"
    [record]="record"
    class="list"
  ></mul-cell-list>

  <mul-cell-text-list
    *ngIf="isFieldType(field.type, enumType.TEXT_LIST)"
    [field]="field"
    [record]="record"
    class="list"
  ></mul-cell-text-list>

  <mul-cell-date
    *ngIf="isFieldType(field.type, enumType.SHORT_DATE)"
    [field]="field"
    [record]="record"
    [type]="enumType.SHORT_DATE"
    class="date"
  ></mul-cell-date>

  <mul-cell-date
    *ngIf="isFieldType(field.type, enumType.LONG_DATE)"
    [field]="field"
    [record]="record"
    [type]="enumType.LONG_DATE"
    class="date"
  ></mul-cell-date>

  <mul-cell-link
    *ngIf="isFieldType(field.type, enumType.LINK)"
    [field]="field"
    [record]="record"
  ></mul-cell-link>
</span>
