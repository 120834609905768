import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridView } from '../../data/type/grid/grid-view.type';
import { UIOptionType } from '../../data/type/filter/UIOption.type';

@Injectable({
  providedIn: 'root'
})
export class FilterStorage {
  private _geographies: BehaviorSubject<UIOptionType[]> = new BehaviorSubject<
    UIOptionType[]
  >([]);

  private _verticals: BehaviorSubject<UIOptionType[]> = new BehaviorSubject<
    UIOptionType[]
  >([]);

  private _industryThemes: BehaviorSubject<
    UIOptionType[]
  > = new BehaviorSubject<UIOptionType[]>([]);

  private _organizations: BehaviorSubject<UIOptionType[]> = new BehaviorSubject<
    UIOptionType[]
  >([]);

  private _targetOrganizations: BehaviorSubject<
    UIOptionType[]
  > = new BehaviorSubject<UIOptionType[]>([]);

  private _buyerOrganizations: BehaviorSubject<
    UIOptionType[]
  > = new BehaviorSubject<UIOptionType[]>([]);

  private _sponsorOrganizations: BehaviorSubject<
    UIOptionType[]
  > = new BehaviorSubject<UIOptionType[]>([]);

  private _gridViews: BehaviorSubject<GridView[]> = new BehaviorSubject<
    GridView[]
  >([]);

  private _activeGridView: BehaviorSubject<string> = new BehaviorSubject<
    string
  >('');

  readonly geographies$ = this._geographies.asObservable();

  readonly verticals$ = this._verticals.asObservable();

  readonly organizations$ = this._organizations.asObservable();

  readonly targetOrganizations$ = this._targetOrganizations.asObservable();

  readonly buyerOrganizations$ = this._buyerOrganizations.asObservable();

  readonly sponsorOrganizations$ = this._sponsorOrganizations.asObservable();

  readonly industryThemes$ = this._industryThemes.asObservable();

  readonly gridViews$ = this._gridViews.asObservable();

  readonly activeGridView$ = this._activeGridView.asObservable();

  get industryThemes(): UIOptionType[] {
    return this._industryThemes.getValue();
  }

  set industryThemes(val: UIOptionType[]) {
    this._industryThemes.next(val);
  }

  get geographies(): UIOptionType[] {
    return this._geographies.getValue();
  }

  set geographies(val: UIOptionType[]) {
    this._geographies.next(val);
  }

  get verticals(): UIOptionType[] {
    return this._verticals.getValue();
  }

  set verticals(val: UIOptionType[]) {
    this._verticals.next(val);
  }

  get organizations(): UIOptionType[] {
    return this._organizations.getValue();
  }

  set organizations(val: UIOptionType[]) {
    this._organizations.next(val);
  }

  get targetOrganizations(): UIOptionType[] {
    return this._targetOrganizations.getValue();
  }

  set targetOrganizations(val: UIOptionType[]) {
    this._targetOrganizations.next(val);
  }

  get buyerOrganizations(): UIOptionType[] {
    return this._buyerOrganizations.getValue();
  }

  set buyerOrganizations(val: UIOptionType[]) {
    this._buyerOrganizations.next(val);
  }

  get sponsorOrganization(): UIOptionType[] {
    return this._sponsorOrganizations.getValue();
  }

  set sponsorOrganization(val: UIOptionType[]) {
    this._sponsorOrganizations.next(val);
  }

  get gridViews(): GridView[] {
    return this._gridViews.getValue();
  }

  set gridViews(val: GridView[]) {
    this._gridViews.next(val);
  }

  get activeGridView(): string {
    return this._activeGridView.getValue();
  }

  set activeGridView(val: string) {
    const defaultGridView = this.gridViews.find(it => it.isDefault)?.id;

    const grid = val !== '' ? val : defaultGridView;

    if (grid) this._activeGridView.next(grid);
  }
}
