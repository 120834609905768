import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataSheetRoutingModule } from './data-sheet-routing.module';
import { PublicDataFilterComponent } from './components/public-data-filter/public-data-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FinancialFilterComponent } from './components/data-filter/financial-filter/financial-filter.component';
import { DataSheetSummaryComponent } from './components/data-sheet-summary/data-sheet-summary.component';
import { DataSheetActionsComponent } from './components/data-sheet-actions/data-sheet-actions.component';
import { DataGridViewSelectorComponent } from './components/data-grid-view-selector/data-grid-view-selector.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DataCellComponent } from './components/data-grid/data-cell/data-cell.component';
import { HeaderCellComponent } from './components/data-grid/header-cell/header-cell.component';
import { SummaryCellComponent } from './components/data-grid/summary-cell/summary-cell.component';
import { DataSheetComponent } from './data-sheet.component';
import { PublicFilterClearComponent } from './components/data-filter/public-filter-clear/public-filter-clear.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataPaginatorComponent } from './components/data-grid/data-paginator/data-paginator.component';
import { CellListComponent } from './components/data-grid/data-cell/cell-list/cell-list.component';
import { CellTextComponent } from './components/data-grid/data-cell/cell-text/cell-text.component';
import { CellImageComponent } from './components/data-grid/data-cell/cell-image/cell-image.component';
import { CellNumberComponent } from './components/data-grid/data-cell/cell-number/cell-number.component';
import { CellDecimalComponent } from './components/data-grid/data-cell/cell-decimal/cell-decimal.component';
import { CellYearComponent } from './components/data-grid/data-cell/cell-year/cell-year.component';
import { CellSwitchComponent } from './components/data-grid/data-cell/cell-switch/cell-switch.component';
import { StoredGridSelectComponent } from './components/data-sheet-actions/stored-grid-select/stored-grid-select.component';
import { StoredGridItemComponent } from './components/data-sheet-actions/stored-grid-item/stored-grid-item.component';
import { SubscriptionModule } from '../subscription/subscription.module';
import { DownloadDataActionComponent } from './components/data-sheet-actions/download-data-action/download-data-action.component';
import { ShareDataActionComponent } from './components/data-sheet-actions/share-data-action/share-data-action.component';
import { SaveDataActionComponent } from './components/data-sheet-actions/save-data-action/save-data-action.component';
import { SelectComponent } from '../uicommon/components/select/select.component';
import { FooterComponent } from '../uicommon/components/footer/footer.component';
import { NavigationModule } from '../navigation/navigation.module';
import { DealFilterComponent } from './components/deal-filter/deal-filter.component';
import { UICommonModule } from '../uicommon/uicommon.module';
import { TargetFilterComponent } from './components/data-filter/target-filter/target-filter.component';
import { DealInfoFilterComponent } from './components/data-filter/deal-info-filter/deal-info-filter.component';
import { BuyerFilterComponent } from './components/data-filter/buyer-filter/buyer-filter.component';
import { DataGridViewService } from './service/data-grid-view.service';
import { DataService } from './service/data-sheet/data.service';
import { StoreModule } from '../store/store.module';
import { CellDateComponent } from './components/data-grid/data-cell/cell-date/cell-date.component';
import { CellLinkComponent } from './components/data-grid/data-cell/cell-link/cell-link.component';
import { CellLongStringComponent } from './components/data-grid/data-cell/cell-long-string/cell-long-string.component';
import { DealFilterClearComponent } from './components/data-filter/deal-filter-clear/deal-filter-clear.component';
import { CellTextListComponent } from './components/data-grid/data-cell/cell-text-list/cell-text-list.component';

@NgModule({
  declarations: [
    DataSheetComponent,
    DataGridComponent,
    PublicDataFilterComponent,
    DealFilterComponent,
    FinancialFilterComponent,
    DataSheetSummaryComponent,
    DataSheetActionsComponent,
    DataGridViewSelectorComponent,
    DataGridComponent,
    DataCellComponent,
    DataCellComponent,
    HeaderCellComponent,
    SummaryCellComponent,
    PublicFilterClearComponent,
    DealFilterClearComponent,
    DataPaginatorComponent,
    CellListComponent,
    CellTextComponent,
    CellImageComponent,
    CellNumberComponent,
    CellDecimalComponent,
    CellYearComponent,
    CellSwitchComponent,
    CellDateComponent,
    StoredGridSelectComponent,
    StoredGridItemComponent,
    DownloadDataActionComponent,
    ShareDataActionComponent,
    SaveDataActionComponent,
    TargetFilterComponent,
    DealInfoFilterComponent,
    BuyerFilterComponent,
    CellLinkComponent,
    CellLongStringComponent,
    CellTextListComponent
  ],
  exports: [DataGridComponent, DataSheetSummaryComponent],
  providers: [DataGridViewService, DataService],
  imports: [
    CommonModule,
    StoreModule,
    DataSheetRoutingModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    SubscriptionModule,
    NgOptimizedImage,
    SelectComponent,
    FooterComponent,
    NavigationModule,
    UICommonModule
  ]
})
export class DataSheetModule {}
