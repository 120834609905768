import { Component, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoredGridService } from '../../../service/stored-grid.service';
import { SnackBarService } from '../../../../uicommon/service/snack-bar.service';
import { AccessPromoService } from '../../../../auth/services/access-promo.service';
import { SnackMessageStatusEnum } from '../../../../uicommon/data/enum/snackMessageStatus.enum';
import { PromoEnum } from '../../../../auth/data/enum/promo.enum';
import { Subscription } from 'rxjs';
import { NavigationItemType } from '../../../../navigation/data/type/navigation-item.type';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { DataType } from '../../../../navigation/data/emun/data-type';
import { StoredGridEnum } from '../../../data/enum/stored-grid/stored-grid.enum';

@Component({
  selector: 'mul-share-data-action',
  templateUrl: './share-data-action.component.html'
})
export class ShareDataActionComponent implements OnDestroy {
  navigationStateSubscription: Subscription = new Subscription();
  navigationState: NavigationItemType = {} as NavigationItemType;

  shareActionState = false;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly spinner: NgxSpinnerService,
    private readonly storedGridFilterService: StoredGridService,
    private readonly snackBarService: SnackBarService,
    private readonly accessPromoService: AccessPromoService,
    private readonly navigationStore: NavigationStore
  ) {
    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationStateSubscription.unsubscribe();
  }

  share() {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.SHARED);

      return;
    }

    this.spinner?.show('share');
    this.shareActionState = true;

    const type =
      this.navigationState.dataType === DataType.PUBLIC
        ? StoredGridEnum.PUBLIC
        : StoredGridEnum.DEAL;

    const url = '/data-sheet/shared/';

    this.storedGridFilterService.save$(type).subscribe(data => {
      navigator.clipboard
        .writeText(window.location.host + url + data.id)
        .then(() => {
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.SUCCESS,
            message: `The current view has been copied to your clipboard. You can share it now with anyone as a live comps table.`
          });
        });

      this.spinner?.hide('share');
      this.shareActionState = false;
    });
  }
}
