export enum FilterRefEnum {
  STATUS = 'status',
  SKU = 'sku',
  TICKER = 'ticker',
  VERTICALS = 'tags',
  COUNTRY = 'countryCode',
  MARKET_CAP = 'marketCap',
  REVENUES = 'revenues',
  EV_REVENUE_GROWTH = 'evRevenueGrowth',
  EBITDA = 'ebitda',
  EBITDA_LTM = 'ebitdaLtm',
  COMPANY = 'shortName',
  EV = 'enterpriseValue',
  TARGET_ID = 'targetId',
  TARGET_NAME = 'targetName',
  TARGET_TAGS = 'targetTags',
  INDUSTRY_THEMES = 'targetThemes',
  TARGET_COUNTRY = 'targetCountry',
  TARGET_COUNTRY_CODE = 'targetCountryCode',
  TARGET_DESCRIPTION = 'targetDescription',
  TARGET_META = 'targetMeta',
  TARGET_EBITDA = 'targetEbitda',
  TARGET_REVENUE = 'targetRevenue',
  BUYER_ID = 'buyerId',
  BUYER_NAME = 'buyerName',
  BUYER_COUNTRY = 'buyerCountry',
  BUYER_COUNTRY_CODE = 'buyerCountryCode',
  BUYER_DESCRIPTION = 'buyerDescription',
  BUYER_META = 'buyerMeta',
  BUYER_TYPE = 'buyerType',
  BUYER_SPONSOR_ID = 'buyerSponsorId',
  BUYER_SPONSOR_NAME = 'buyerSponsorName',
  DEAL_EV = 'dealEnterpriseValue',
  DEAL_EV_REVENUE = 'dealEnterpriseValueRevenue',
  DEAL_EV_EBITDA = 'dealEnterpriseValueEbitda',
  DEAL_TYPE = 'dealType',
  DEAL_DATE = 'dealDate'
}
