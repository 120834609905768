import { NgModule } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AccountRecoveryComponent } from './components/account-recovery/account-recovery.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';

const loginRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'account-recovery',
    component: AccountRecoveryComponent
  },
  {
    path: 'unauthorized-access',
    component: ForbiddenComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'sign-up/:product',
    component: SignUpComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(loginRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
