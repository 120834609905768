<div class="header-main">
  <span
    [ngStyle]="{ 'width.px': header.header.width }"
    class="{{ generateClassNameFromLabel(header.header.label) }}"
  >
    <div class="header-text">
      <strong *ngFor="let label of header.header.label.split('|')">{{
        label
      }}</strong>
    </div>
    <button
      (click)="sortColumn(header.fields)"
      *ngIf="!header.subheaders || header.subheaders.length === 0"
      class="sort"
    >
      <i class="fa-solid fa-sort sort"></i>
    </button>
  </span>
</div>

<div *ngIf="header.subheaders" class="header-sub">
  <span
    *ngFor="let subheader of header.subheaders"
    [ngStyle]="{ 'width.px': subheader.width }"
  >
    <span>{{ subheader.label }}</span>
    <button
      (click)="sortColumn(subheader.fields)"
      *ngIf="header.subheaders.length > 0"
      class="sort"
    >
      <i class="fa-solid fa-sort sort"></i>
    </button>
  </span>
</div>
