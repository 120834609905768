export type TargetFilterType = {
  keywords: string[];
  verticalSelection: string[];
  organizationSelection: string[];
  geographySelection: string[];
  themeSelection: string[];
  revenue: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
  ebitda: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
};

export class TargetFilterFactory {
  static generate() {
    return <TargetFilterType>{
      keywords: [],
      verticalSelection: [],
      organizationSelection: [],
      themeSelection: [],
      includeOrganizations: false,
      conditionalIncludeOrganizations: false,
      geographySelection: [],
      revenue: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      },
      ebitda: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      }
    };
  }
}
